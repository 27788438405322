<template>
  <b-modal v-model="showModal" ref="modalSelectProduct" centered @hidden="hide">
    <template #modal-header>Select Branch</template>
    <b-input-group class="mt-2">
      <b-form-input
        placeholder="Search Stock Name"
        v-model.trim="filter.search"
        @keyup.enter="handleSearch"
      ></b-form-input>
      <b-input-group-append>
        <b-input-group-text>
          <b-iconstack
            font-scale="2"
            type="submit"
            @click.prevent="handleSearch"
          >
            <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
          </b-iconstack>
        </b-input-group-text>
      </b-input-group-append>
    </b-input-group>
    <b-table
      striped
      hover
      responsive
      :items="items"
      :fields="fields"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
      class="mt-2"
    >
      <template v-slot:cell(name)="data">
        <p class="m-0 text-black">
          {{ data.item.name }}
        </p>
      </template>
      <template v-slot:cell(action)="{ item }">
        <div>
          <b-button
            variant="primary-color"
            class=""
            :class="buttonClass(item)"
            @click="selectItem(item)"
          >
            <div>{{ isSelected(item) ? "Selected" : "Select" }}</div>
          </b-button>
        </div>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
    </b-table>
    <Pagination
      @handleChangeTake="handleChangeTake"
      @pagination="pagination"
      :filter="filter"
      :rows="rows"
    />
    <template #modal-footer>
      <b-row>
        <b-col class="text-left">
          <b-button @click.prevent="hide(1)" class="border-btn"
            >Cancel</b-button
          >
        </b-col>
      </b-row>
      <b-row> </b-row>
    </template>
  </b-modal>
</template>

<script>
export default {
  components: {},
  props: {
    selectedBranch: {
      required: false,
    },
    selectedBranchStock: {
      required: false,
    },
  },
  data() {
    return {
      fields: [
        { key: "action", label: "" },
        { key: "name", label: "Name" },
      ],
      items: [],
      showModal: false,
      isBusy: false,
      rows: 0,
      filter: {
        page: 1,
        take: 5,
        search: "",
        status: [1],
      },
    };
  },
  async mounted() {
    // if (this.productLists?.length > 0) {
    //   await this.checkedSelect();
    // }
  },
  watch: {
    // productLists: function () {
    //   if (this.productLists?.length > 0) {
    //     this.productSelect = this.selected;
    //     this.checkedSelect();
    //   }
    // },
  },
  methods: {
    async show() {
      this.showModal = true
      await this.getBranchList();
    },

    hide() {
      this.filter = {
        page: 1,
        take: 5,
        search: "",
        status: [1],
      };
      this.showModal = false;
    },
    handleSearch() {
      this.getBranchList();
    },
    pagination(value) {
      this.filter.page = value;
      this.getBranchList();
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getBranchList();
    },

    async getBranchList() {
      this.isBusy = true;
      const res = await this.axios.post(`/branch/list`, this.filter);
      this.items = res.data.detail.data;
      this.rows = res.data.detail.total;
      this.isBusy = false;
    },
    selectItem(item) {
      this.hide();
      if (this.selectedBranch == item.id) {
        item.id = 0;
        item.name = "";
      }
      this.$emit("submit", item);
    },
    isSelected(item) {
      return this.selectedBranch == item.id;
    },
    buttonClass(item) {
      return this.isSelected(item) ? "border-btn" : "submit-btn";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
  font-weight: 600;
}
::v-deep .modal-dialog {
  max-width: 800px;
}
::v-deep .input-group-text {
  padding: 0;
  background-color: transparent;
}
::v-deep thead {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
  text-align: center;
}
::v-deep .modal-footer {
  justify-content: space-between;
}
.btn-save {
  color: #fff;
  background-color: var(--primary-color);
  width: 100%;
  border: none;
  padding: 5px 50px;
  border-radius: 0px;
}
.btn-cancel {
  color: #fff;
  background-color: #39424e;
  width: 100%;
  border: none;
  padding: 5px 50px;
  border-radius: 0px;
}
.pic-box {
  margin: auto !important;
  width: 50%;
  padding-top: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
::v-deep .table-width {
  width: 150px;
}

.btn.disabled,
.btn:disabled:hover {
  opacity: 0.65;
  color: #fff;
}
</style>

<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <h1 class="mr-sm-4 header-tablepage">Repair</h1>
        <div class="title-tabs mt-3">Repair Variable</div>
        <div class="bg-white p-3">
          <b-row>
            <b-col cols="6">
              <InputText
                textFloat="Prefix"
                placeholder="Prefix"
                isRequired
                v-model="form.prefix"
                :v="$v.form.prefix"
                :isValidate="$v.form.prefix.$error"
                @blur="verifyPrefix"
              />
              <div v-if="prefixMessage" class="text-error">
                {{ prefixMessage }}
              </div>
            </b-col>
            <b-col cols="6">
              <!-- <section>
                <div class="title">Example :</div>
                <p>
                  {{ "{{" }}MM}} <span class="text-error">*</span> : 2 Digit of
                  Month of Transaction eg. 01,02,12 <br />
                  {{ "{{" }}YY}} <span class="text-error">*</span> : 2 Digit of
                  Year of Transaction eg. Year 2020 : 20
                  <br />
                  {{ "{{" }}BRANCH_CODE}} <span class="text-error">*</span> :
                  Branch Code
                  <br />
                  {{ "{{" }}DD}} : Day 2 Digit <br />
                  {{ "{{" }}RUNNING_NO}} <span class="text-error">*</span> :
                  Running Number
                </p>
              </section> -->
            </b-col>
            <b-col>
              <label> No.Length (Reset every month) </label>

              <b-form-group class="mb-0" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="radio-group-1"
                  v-model="form.runningNoLength"
                  :options="lengthNoList"
                  :aria-describedby="ariaDescribedby"
                  name="radio-options"
                ></b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col cols="6"> </b-col>
            <b-col cols="6">
              <InputTextArea
                v-model="form.footerMessage"
                textFloat="Footer Message"
                placeholder="Footer Message"
                rows="3"
                class="mt-2"
                isHideLength
                :maxLength="100000"
                maxCharacter="1000000"
              />
            </b-col>
          </b-row>
        </div>
        <div class="title-tabs">Repair Status</div>
        <div class="bg-white p-3">
          <b-row>
            <b-col cols="1">Option</b-col>
          </b-row>
          <b-row class="mt-2 align-items-center">
            <template v-for="(field, index) of options">
              <b-col
                cols="1"
                class="text-center mb-2"
                :key="'sort-order' + field.id + '-' + index"
              >
                <div class="content-between">
                  <font-awesome-icon
                    icon="chevron-circle-up"
                    :class="`icon-size ${
                      index == 0 ||
                      field.isDefault ||
                      options[index - 1].isDefault
                        ? 'disabled'
                        : 'pointer'
                    }`"
                    @click="sortData(field, index, false)"
                  />
                  {{ index + 1 }}
                  <font-awesome-icon
                    icon="chevron-circle-down"
                    :class="`icon-size ${
                      index == options.length - 1 ||
                      field.isDefault ||
                      options[index + 1].isDefault
                        ? 'disabled'
                        : 'pointer'
                    }`"
                    @click="sortData(field, index, true)"
                  />
                </div>
              </b-col>
              <b-col cols="2" :key="'name' + field.id + '-' + index">
                <InputText
                  v-if="field.id !== 999"
                  class=""
                  :disabled="field.isDefault"
                  textFloat=""
                  :placeholder="field.name"
                  v-model="field.name"
                  type="text"
                >
                </InputText>
                <div v-else class="my-2">
                  <b-button
                    class="mb-2"
                    variant="custom-link mr-auto"
                    @click="addField(index)"
                  >
                    Add Option
                  </b-button>
                </div>
              </b-col>
              <b-col cols="2" :key="'delete' + field.id + '-' + index">
                <font-awesome-icon
                  v-if="!field.isDefault && field.id !== 999"
                  @click="deleteStatus(field.sort_order)"
                  icon="trash-alt"
                  class="mb-2"
                ></font-awesome-icon
              ></b-col>
              <b-col cols="7"></b-col>
            </template>
          </b-row>
        </div>
        <div class="title-tabs">Repair Stock</div>
        <div class="bg-white p-3">
          <b-row>
            <b-col cols="6">
              <div class="input-action">
                <InputText
                  textFloat="Stock to Receive"
                  v-model="form.stockRecieveName"
                  placeholder="Stock to Receive"
                  type="text"
                  readonly
                  @inputClick="handleStock(1)"
                  customLabel
                >
                </InputText>
                <div class="input-action-icon">
                  <b-icon
                    stacked
                    icon="search"
                    scale="0.5"
                    variant="grey"
                    @click="handleStock(1)"
                  ></b-icon>
                </div>
              </div>
            </b-col>
            <b-col cols="6"></b-col>
            <b-col cols="6">
              <div class="input-action">
                <InputText
                  textFloat="Stock to Return"
                  v-model="form.stockReturnName"
                  placeholder="Stock to Return"
                  type="text"
                  readonly
                  @inputClick="handleStock(2)"
                  customLabel
                >
                </InputText>
                <div class="input-action-icon">
                  <b-icon
                    stacked
                    icon="search"
                    scale="0.5"
                    variant="grey"
                    @click="handleStock(2)"
                  ></b-icon>
                </div>
              </div>
            </b-col>
            <b-col cols="6"></b-col>
            <b-col cols="6">
              <b-form-checkbox
                v-model="form.isTransferRepairBranch"
                class="radio-active mt-2"
                :value="true"
                :unchecked-value="false"
                size="md"
              >
                Transfer to Repair Branch</b-form-checkbox
              >
            </b-col>

            <template v-if="form.isTransferRepairBranch">
              <b-col cols="6"></b-col>
              <b-col cols="6">
                <div class="input-action mt-2">
                  <InputText
                    textFloat="Repair Branch Send to"
                    v-model="form.repairBranchName"
                    placeholder="Repair Branch Send to"
                    type="text"
                    readonly
                    @inputClick="handleBranch"
                    customLabel
                  >
                  </InputText>
                  <div class="input-action-icon">
                    <b-icon
                      stacked
                      icon="search"
                      scale="0.5"
                      variant="grey"
                      @click="handleBranch"
                    ></b-icon>
                  </div>
                </div>
              </b-col>
              <b-col cols="6"></b-col>
              <b-col cols="6">
                <div class="input-action mt-1">
                  <InputText
                    textFloat="Repair Branch Stock"
                    v-model="form.repairBranchStockName"
                    placeholder="Repair Branch Stock"
                    type="text"
                    readonly
                    @inputClick="handleStock(3)"
                    customLabel
                  >
                  </InputText>
                  <div class="input-action-icon">
                    <b-icon
                      stacked
                      icon="search"
                      scale="0.5"
                      variant="grey"
                      @click="handleStock(3)"
                    ></b-icon>
                  </div>
                </div>
              </b-col>
              <b-col cols="6"></b-col>
              <b-col cols="6">
                <div class="input-action mt-1">
                  <InputText
                    textFloat="Inventory Transfer From - Send"
                    v-model="form.inventoryTransferFormSendName"
                    placeholder="Inventory Transfer From - Send"
                    type="text"
                    readonly
                    @inputClick="handleStock(4)"
                    customLabel
                  >
                  </InputText>
                  <div class="input-action-icon">
                    <b-icon
                      stacked
                      icon="search"
                      scale="0.5"
                      variant="grey"
                      @click="handleStock(4)"
                    ></b-icon>
                  </div>
                </div>
              </b-col>
              <b-col cols="6"></b-col>
              <b-col cols="6">
                <div class="input-action mt-1">
                  <InputText
                    textFloat="Inventory Transfer From - Return"
                    v-model="form.inventoryTransferFormReturnName"
                    placeholder="Inventory Transfer From - Return"
                    type="text"
                    readonly
                    @inputClick="handleStock(5)"
                    customLabel
                  >
                  </InputText>
                  <div class="input-action-icon">
                    <b-icon
                      stacked
                      icon="search"
                      scale="0.5"
                      variant="grey"
                      @click="handleStock(5)"
                    ></b-icon>
                  </div>
                </div>
              </b-col>
            </template>
          </b-row>
        </div>
        <div class="title-tabs">Warranty Condition</div>
        <div class="bg-white p-3">
          <TextEditorsTiny
            textFloat=""
            placeholder="Warranty Condition"
            :value="form.repairWarranttyCondition"
            @onDataChange="(val) => (form.repairWarranttyCondition = val)"
            v-model="form.repairWarranttyCondition"
          />
        </div>
      </div>
    </div>
    <FooterAction @submit="updateDetial()" routePath="/setting/repair" />
    <ModalStock
      ref="modalStock"
      @submit="selectStock"
      :selectedReturn="form.stockReturnId"
      :selectedReceive="form.stockRecieveId"
      :selectedBranchStock="form.repairBranchStockId"
      :selectedsendInventory="form.inventoryTransferFormSend"
      :selectedReturnInventory="form.inventoryTransferFormReturn"
    ></ModalStock>
    <ModalSelectBranch
      ref="modalSelectBranch"
      @submit="selectBranch"
      :selectedBranch="form.repairBranchId"
      :selectedBranchStock="form.repairBranchStockId"
    ></ModalSelectBranch>
  </div>
</template>
<script>
import OtherLoading from "@/components/loading/OtherLoading";
import { required } from "vuelidate/lib/validators";
import ModalStock from "./components/ModalStockorIventory.vue";
import ModalSelectBranch from "./components/ModalSelectBranch.vue";

export default {
  components: { OtherLoading, ModalStock, ModalSelectBranch },
  validations() {
    return {
      form: {
        prefix: { required },
      },
    };
  },
  data() {
    return {
      isLoading: false,
      form: {
        prefix: "",
        runningNoLength: 0,
        footerMessage: "",
        stockReturnId: null,
        stockRecieveId: null,
        stockReturnName: "",
        stockRecieveName: "",
        repairBranchStockId: 0,
        repairBranchStockName: "",
        repairBranchId: 0,
        repairBranchName: "",
        isTransferRepairBranch: false,
        repairWarranttyCondition: "",
        repairStatus: [],
        inventoryTransferFormSend: 0,
        inventoryTransferFormSendName: "",
        inventoryTransferFormReturn: 0,
        inventoryTransferFormReturnName: "",
      },
      lengthNoList: [
        { text: "4", value: 4 },
        { text: "5", value: 5 },
        { text: "6", value: 6 },
        { text: "7", value: 7 },
        { text: "8", value: 8 },
        { text: "9", value: 9 },
        { text: "10", value: 10 },
      ],
      listStockReceive: [],
      listStockReturn: [],
      selectedReceiveIndex: null,
      selectedReceive: null,
      prefixMessage: "",
      options: [],
    };
  },
  async created() {
    await this.getDetail();
  },
  methods: {
    async getDetail() {
      this.$bus.$emit("showLoading");
      const res = await this.axios("/repair/setting");
      this.form = res.data.detail;
      // this.form.warranty_condition = "test";
      this.options = [...this.form.repairStatus];
      const index = this.options.findIndex((item) => item.id === 2);
      if (index !== -1) {
        this.options.splice(index, 0, {
          id: 999,
          name: "Add option",
          sort_order: index,
          created_time: "2025-03-27T17:38:37",
          created_by: "eb3f34ae-136b-47de-b485-be64896fb041",
        });

        this.options.forEach((item, i) => {
          item.sort_order = i;
        });
      }
      this.$bus.$emit("hideLoading");
    },
    async updateDetial() {
      this.$v.form.$touch();
      if (this.$v.form.$error || this.prefixMessage) {
        return;
      }
      this.$bus.$emit("showLoading");
      const res = await this.axios.put("/repair/setting", this.form);
      if (res.data.result) {
        this.successAlert().then(() => {
          this.$router.push("/setting/repair");
        });
      } else {
        this.errorAlert(res.data.message);
      }
      this.$bus.$emit("hideLoading");
    },
    async verifyPrefix(value) {
      if (value.target.value) {
        const params = new URLSearchParams();
        params.append("prefix", value.target.value);
        const resp = await this.axios(
          `/InventoryTransfer/ValidatePrefix?${params}`
        );
        if (!resp.data.detail) this.prefixMessage = resp.data.message;
        else this.prefixMessage = null;
      } else {
        this.prefixMessage = null;
      }
    },
    handleStock(type) {
      this.$refs.modalStock.show(type);
    },
    handleBranch() {
      this.$refs.modalSelectBranch.show();
    },
    selectStock(val, type) {
      const fieldMap = {
        1: { name: "stockRecieveName", id: "stockRecieveId" },
        2: { name: "stockReturnName", id: "stockReturnId" },
        3: { name: "repairBranchStockName", id: "repairBranchStockId" },
        4: {
          name: "inventoryTransferFormSendName",
          id: "inventoryTransferFormSend",
        },
        5: {
          name: "inventoryTransferFormReturnName",
          id: "inventoryTransferFormReturn",
        },
      };

      const field = fieldMap[type];
      if (field) {
        this.form[field.name] = val.name;
        this.form[field.id] = val.id;
      }
    },
    selectBranch(val) {
      this.form.repairBranchName = val.name;
      this.form.repairBranchId = val.id;
    },
    async sortData(items, index, val) {
      if (index == 0 && !val) {
        return;
      }

      if (
        items.isDefault ||
        (this.options[index + 1].isDefault && val) ||
        (this.options[index - 1].isDefault && !val)
      ) {
        return;
      }

      if (val && index == this.options.length - 1) return;

      this.options.splice(index, 1);
      index = val ? index + 1 : index - 1;

      this.options.splice(index, 0, items);
      var index = 0;
      for (const fields of this.options) {
        fields.sort_order = index;
        index++;
      }
      this.$nextTick(() => {
        this.options = [...this.options];
        // this.sortArray();
      });
    },
    addField(index) {
      this.options.splice(index, 0, {
        id: 0,
        name: "Add Option",
        sort_order: index,
        isDefault: 0,
        active: 1,
        value: "",
      });

      this.options.forEach((item, i) => {
        item.sort_order = i;
      });
      let repairStatus = [...this.options.filter(x => x.id !== 999)];
      repairStatus.forEach((item, index) => {
        item.sort_order = index;
      });
      this.form.repairStatus = repairStatus
    },
    deleteStatus(sort) {
      this.options = this.options.filter((item) => item.sort_order !== sort);
      this.form.repairStatus = this.form.repairStatus.filter(
        (item) => item.sort_order !== sort
      );
      this.form.repairStatus.forEach((item, index) => {
        item.sort_order = index;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.input-action {
  position: relative;
  width: 100%;

  ::v-deep .input-custom input {
    cursor: pointer !important;
  }

  .input-action-icon {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 27px;
    right: 5px;
    width: 32px;
    height: 32px;
    background-color: inherit;
    color: var(--primary-color);
  }
}

.input-action-icon {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 32px;
  right: 5px;
  width: 32px;
  height: 32px;
  background-color: inherit;
  color: var(--primary-color);
}

.icon-size {
  font-size: 20px;
  color: #575757;
  cursor: pointer;
}
.icon-size.disabled {
  color: #bababa;
}
</style>
